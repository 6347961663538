
import {
    CHANGE_SCREEN
   } from '../types';

   export const ChangeScreen = data  => dispatch => {
       dispatch({
         type: CHANGE_SCREEN,
         payload: data,
       });
     };
   