import {
    CHANGE_SCREEN
} from '../types';

const stateScreen = {
    screen: 'chat',
  };
  
  const ScreenReducer = function(state = stateScreen, action) {
    switch (action.type) {
      case CHANGE_SCREEN:
        return {
          ...state,
          screen: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ScreenReducer;
  