import React from 'react'
import { MuiFileInput } from 'mui-file-input'

const InputFiles = ({handle}) => {
  const [value, setValue] = React.useState(null)

 
  const handleChange = (newValue) => {
    handle(newValue);
  };
  return <MuiFileInput value={value}
     inputProps={{ accept: ' .pdf, .docx, .txt, .csv' }}
    onChange={handleChange} />
}

export default InputFiles;