import {
    createBrowserRouter,
    Route,
    Routes
  } from "react-router-dom";
import  ChatSteren  from "../components/ChatSteren";
import  Settings  from "../screens/Settings";
import Main from "../screens/Main";

 export const router = createBrowserRouter([
    {
      path: "/",
      element: <Main/> ,
      children: [
        {

        path:"settings",
        element: <Settings/>
       }, 
       {
        path: "chat",
        element: <ChatSteren/> ,
       }
      ],
      
    }
  ]);