import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { BotIconNotSpeaking, BotIconSpeaking } from '../assets';
import LongPressButton from './LongPress';
import {Button} from '@mui/material';
import {  useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../redux/actions';
const Header = ({isMobile, navigation, width, setSpeak= Function}) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.Screen.screen);
  const styles = {
    header: {
      height:!isMobile ? 100 : 40,
      backgroundColor:'white',
      display:'flex',
      flexDirection:'row',
      width:width,
      borderBottomLeftRadius: '30%',
      borderBottomRightRadius: '20%',
    }
  }


  const [animation, setAnimation] = useState({
    talking:false, noTalking:false
  })
  useEffect(() =>{
    setSpeak(isSpeaking);
  },[isSpeaking])

  useEffect(() => {
   
    if(animation.noTalking){
      setTimeout(() =>{
        setIsSpeaking(true)
        setTimeout(() =>{
          setAnimation({
            talking:true, noTalking:false
          })
        }, 400)
      },3000)
    }

    if(animation.talking){
      setTimeout(() =>{
        setIsSpeaking(false)
        setAnimation({
          talking:false, noTalking:false
        })

      },3000)
    }
  }, [animation])


  return (
    <header style={styles.header} >
      <Container  >
        <Box py={4} sx={{ display:'flex', flexDirection:'row',justifyContent:'center' }} >
          <LongPressButton
          onPress={() =>setIsSpeaking(!isSpeaking)}
          onLongPress={()=>{}}
          >
          {isSpeaking ? 
        <BotIconSpeaking onClick={() =>setIsSpeaking(!isSpeaking)} />
          :
        <BotIconNotSpeaking onClick={() =>setIsSpeaking(!isSpeaking)} />
          }
          </LongPressButton>
          <Button variant="outlined" 
          onClick={() => {
            const nameScreen = screen === 'chat' ? 'settings' :  'chat'
            dispatch(Actions.ChangeScreen(nameScreen));
            navigate(nameScreen)
          }}
          sx={{position:'absolute',   right:100}}>
            {screen === 'chat' ? 'Configuración' :  'Chat' }
          </Button>

        </Box>   
      </Container>
    </header>
  );
  
};

export default Header;

