import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import useScreenSize from '../hooks/resize';
import { Box } from '@mui/material';
import Header from '../components/Header';

const Main = () => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const {  width } = useScreenSize();
  return (
    <Box>
        <Header  width={width} setSpeak={setIsSpeaking}  />
        <Outlet />
    </Box>

  )
}

export default Main