import axios from 'axios';

export const URL_API = 'https://api-steren-8f687e44686f.herokuapp.com/chat';
//export const URL_API = 'http://127.0.0.1:8000/chat';

  function config(URL) {
    return {
      baseURL: URL,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',

            },
    };
}
  
export const API = async () => axios.create( config(URL_API));

export const ROUTES = {
//chat Ai respuesta
GET_AI_RESPONS:'chat',

//templates
CREATE_TEMPLATE:'template/create',
GET_TEMPLATES:'template/get'
}