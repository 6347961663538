import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

const FilesUploaded = ({documents}) => {
const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
    function generate(element) {
        return documents.map((value) =>
          React.cloneElement(element, {
            key: value,
          }),
        );
      }
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: 'transparent',
      }));
  return (
    <Grid item xs={12} md={6} style={{flex:2, justifyContent:'center', backgroundColor:'transparent', }} >
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
           Archivos subidos
          </Typography>
          <Demo>
            <List dense={dense}>
                {documents.map((item) => (
                 <ListItem
                 secondaryAction={
                   <IconButton edge="end" aria-label="delete">
                     <DeleteIcon />
                   </IconButton>
                 }
               >
                 <ListItemAvatar>
                   <Avatar>
                     <FolderIcon />
                   </Avatar>
                 </ListItemAvatar>
                 <ListItemText
                   primary={item.name}
                   secondary={secondary ? 'Secondary text' : null}
                 />
               </ListItem>
                ))}

            </List>
          </Demo>
        </Grid>
  )
}

export default FilesUploaded;