import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PdfToText from '../components/PdfToText';
import FilesUploaded from '../components/FilesUploaded';
import axios from 'axios';
import InputFiles from '../components/InputFiles';
const uri = 'https://api-steren-8f687e44686f.herokuapp.com/'
const Settings = () => {
    const [textFile, setTextFile] = useState('');
    const [description, setDesciption] = useState('');
    const [documents, setDocuments] = useState([]);
    const handleSave = async() => {
       await axios.post(uri+'setBot', {
        prompt: description   
      })
    }

    const getPromtp = async() => {
      const response = await axios.get(uri+'getBotPrompt');
      setDesciption(response.data);
    }
    const getDocuments = async() => {
      const response = await axios.get(uri+'getDocuments');
      console.log("estos son los docs", response.data);
      if(response.data.length > 0){
        setDocuments(response.data);
      }
    }

    async function uploadFileToAPI(file) {
      try {
        console.log("file desde upload", file);
          // Crear un objeto FormData para contener el archivo que se subirá
          const formData = new FormData();
          formData.append('file', file);
  
          // Realizar la solicitud POST usando axios
          const response = await axios.post(uri+'upload', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
  
          console.log(response.data);
          getDocuments();
          return response.data;
  
      } catch (error) {
          console.error('Error al subir el archivo:', error);
          throw error;
      }
  }

  
    useEffect(() => {
      getPromtp();
      getDocuments();
    },[])

    return (
        <div>
          <React.Fragment>
            <CssBaseline />
            <Container fixed>
              <Box
                sx={{
                  bgcolor: '#f0f0f0',
                  borderRadius: '10px',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <h2 style={{ marginBottom: '20px', }}>Configura tu bot</h2>
                  <h3 style={{ textAlign: 'center', 
                    textAlign:'start', marginTop:20 }}>
                    Configura su personalidad
                  </h3>
                      <TextField
                        fullWidth
                        placeholder={'Agrega una descripción'}
                        multiline
                        value={description}
                        rows={4}
                        onChange={(e) => setDesciption(e.target.value)}
                      />
                  <h3 style={{ textAlign: 'center',
                    textAlign:'start', marginTop: '20px' }}>
                    Agrega conocimiento base
                  </h3>
                  <Box sx={{ display:'flex', 
                      justifyContent:'space-around', alignItems:'center'}} >
                     <FilesUploaded  documents={documents} nameFile={'Archivo.pdf'}  />
                     <div style={{ flex:4 }} >
                     < InputFiles handle={uploadFileToAPI}  />
                      </div>
                  </Box>
                 
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    marginTop: '20px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    width: '200px',
                    height: '50px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    backgroundColor: '#2196f3',
                    color: '#ffffff',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#1976d2',
                    },
                  }}
                  onClick={handleSave}>
                  Guardar
                </Button>
              </Box>
            </Container>
          </React.Fragment>
        </div>
      );
}

export default Settings